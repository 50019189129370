import apiService from "./apiService"

const AuthenticationServices = () => {
    const generateToken = async () => {
        const { data: { key } } = await apiService.get('/auth/generated-token');
        return key;
    }

    const checkUser = async () => {
        try {
            const lastIdentify = localStorage.getItem('lastIdentify');
            const lastCheckUser = localStorage.getItem('_lcu');
            const lastCheckUserTime = localStorage.getItem('_lcut');
            const now = new Date().getTime();
            const isExpired = now - lastCheckUserTime > (1000 * 60 * 60 * 24);

            if (lastIdentify && lastCheckUser && !isExpired) {
                if (lastIdentify === JSON.parse(lastCheckUser).uuid) {
                    return JSON.parse(lastCheckUser);
                }
            }

            const { data } = await apiService.get('/user-profile/check_user/');

            localStorage.setItem('_lcu', JSON.stringify(data[0]));
            localStorage.setItem('_lcut', now);
            return data[0];
        } catch {
            return null;
        }
    }

    return {
        generateToken,
        checkUser
    }
}

export default AuthenticationServices
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth, getIdToken } from 'firebase/auth';
import API_URL from '../../config/API_URL';
import apiService from '../../services/apiService';
import { IDBServices } from 'assemblr-datasource';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    let token = localStorage.getItem('_token');
    if (token) {
      headers.set('Authorization', token);
    }
    const { currentUser } = getAuth();
    if (currentUser) {
      try {
        token = await getIdToken(currentUser);
        if (token) {
          headers.set('Authorization', token);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return headers;
  },
});

export default baseQuery;

export const axiosBaseQuery = async ({
  url, method, data, params, headers, ...rest
}) => {
  console.log('___axiosBaseQuery', url, method, data, params, headers, rest);

  const cacheds = ['my-creations'].some((cached) => url.includes(cached));
  const uid = getAuth().currentUser?.uid;
  try {
    if (cacheds) {
      try {
        const cachedData = await IDBServices.getApi({ key: `${uid}-${url}` });
        if (cachedData) {
          return { data: cachedData };
        }
      } catch (e) {
        console.log('Error getting cached data', e);
      }
    }

    const result = await apiService({
      url,
      method,
      data,
      params,
      headers,
    });


    if (cacheds) {
      await IDBServices.setApi({ key: `${uid}-${url}`, value: result.data });
    }

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

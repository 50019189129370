const le = (e, t) => t.some((n) => e instanceof n);
let Pe, De;
function gt() {
  return Pe || (Pe = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function Et() {
  return De || (De = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const fe = /* @__PURE__ */ new WeakMap(), se = /* @__PURE__ */ new WeakMap(), Z = /* @__PURE__ */ new WeakMap();
function St(e) {
  const t = new Promise((n, r) => {
    const s = () => {
      e.removeEventListener("success", i), e.removeEventListener("error", o);
    }, i = () => {
      n(_(e.result)), s();
    }, o = () => {
      r(e.error), s();
    };
    e.addEventListener("success", i), e.addEventListener("error", o);
  });
  return Z.set(t, e), t;
}
function Rt(e) {
  if (fe.has(e))
    return;
  const t = new Promise((n, r) => {
    const s = () => {
      e.removeEventListener("complete", i), e.removeEventListener("error", o), e.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(e.error || new DOMException("AbortError", "AbortError")), s();
    };
    e.addEventListener("complete", i), e.addEventListener("error", o), e.addEventListener("abort", o);
  });
  fe.set(e, t);
}
let de = {
  get(e, t, n) {
    if (e instanceof IDBTransaction) {
      if (t === "done")
        return fe.get(e);
      if (t === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return _(e[t]);
  },
  set(e, t, n) {
    return e[t] = n, !0;
  },
  has(e, t) {
    return e instanceof IDBTransaction && (t === "done" || t === "store") ? !0 : t in e;
  }
};
function $e(e) {
  de = e(de);
}
function Ot(e) {
  return Et().includes(e) ? function(...t) {
    return e.apply(pe(this), t), _(this.request);
  } : function(...t) {
    return _(e.apply(pe(this), t));
  };
}
function Tt(e) {
  return typeof e == "function" ? Ot(e) : (e instanceof IDBTransaction && Rt(e), le(e, gt()) ? new Proxy(e, de) : e);
}
function _(e) {
  if (e instanceof IDBRequest)
    return St(e);
  if (se.has(e))
    return se.get(e);
  const t = Tt(e);
  return t !== e && (se.set(e, t), Z.set(t, e)), t;
}
const pe = (e) => Z.get(e);
function xt(e, t, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(e, t), c = _(o);
  return r && o.addEventListener("upgradeneeded", (l) => {
    r(_(o.result), l.oldVersion, l.newVersion, _(o.transaction), l);
  }), n && o.addEventListener("blocked", (l) => n(
    // Casting due to https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/1405
    l.oldVersion,
    l.newVersion,
    l
  )), c.then((l) => {
    i && l.addEventListener("close", () => i()), s && l.addEventListener("versionchange", (u) => s(u.oldVersion, u.newVersion, u));
  }).catch(() => {
  }), c;
}
const At = ["get", "getKey", "getAll", "getAllKeys", "count"], Ct = ["put", "add", "delete", "clear"], oe = /* @__PURE__ */ new Map();
function Be(e, t) {
  if (!(e instanceof IDBDatabase && !(t in e) && typeof t == "string"))
    return;
  if (oe.get(t))
    return oe.get(t);
  const n = t.replace(/FromIndex$/, ""), r = t !== n, s = Ct.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || At.includes(n))
  )
    return;
  const i = async function(o, ...c) {
    const l = this.transaction(o, s ? "readwrite" : "readonly");
    let u = l.store;
    return r && (u = u.index(c.shift())), (await Promise.all([
      u[n](...c),
      s && l.done
    ]))[0];
  };
  return oe.set(t, i), i;
}
$e((e) => ({
  ...e,
  get: (t, n, r) => Be(t, n) || e.get(t, n, r),
  has: (t, n) => !!Be(t, n) || e.has(t, n)
}));
const Pt = ["continue", "continuePrimaryKey", "advance"], Ne = {}, he = /* @__PURE__ */ new WeakMap(), Ke = /* @__PURE__ */ new WeakMap(), Dt = {
  get(e, t) {
    if (!Pt.includes(t))
      return e[t];
    let n = Ne[t];
    return n || (n = Ne[t] = function(...r) {
      he.set(this, Ke.get(this)[t](...r));
    }), n;
  }
};
async function* Bt(...e) {
  let t = this;
  if (t instanceof IDBCursor || (t = await t.openCursor(...e)), !t)
    return;
  t = t;
  const n = new Proxy(t, Dt);
  for (Ke.set(n, t), Z.set(n, pe(t)); t; )
    yield n, t = await (he.get(n) || t.continue()), he.delete(n);
}
function Le(e, t) {
  return t === Symbol.asyncIterator && le(e, [IDBIndex, IDBObjectStore, IDBCursor]) || t === "iterate" && le(e, [IDBIndex, IDBObjectStore]);
}
$e((e) => ({
  ...e,
  get(t, n, r) {
    return Le(t, n) ? Bt : e.get(t, n, r);
  },
  has(t, n) {
    return Le(t, n) || e.has(t, n);
  }
}));
const P = async () => await xt("assemblr-db", 1, {
  upgrade(e) {
    const t = e.createObjectStore("creations", {
      keyPath: "Id",
      autoIncrement: !0
    });
    t.createIndex("HostID", "HostID"), t.createIndex("Slug", "Slug"), t.createIndex("Pblr", "Pblr"), e.createObjectStore("preferences", {
      keyPath: "key"
    }), e.createObjectStore("apis", {
      keyPath: "key"
    });
  }
}), N = {
  async initDB() {
    await P();
  },
  async getMyCreations({ hostId: e }) {
    return await (await P()).transaction("creations", "readonly").objectStore("creations").index("HostID").getAll(e);
  },
  async updateOrCreateCreations({ creations: e }) {
    const r = (await P()).transaction("creations", "readwrite").objectStore("creations");
    await Promise.all(e.map(async (s) => {
      await r.get(s.Id) ? await r.put(s) : await r.add(s);
    }));
  },
  async getPreference({ key: e }) {
    const s = await (await P()).transaction("preferences", "readonly").objectStore("preferences").get(e);
    return (s == null ? void 0 : s.value) || null;
  },
  async setPreference({ key: e, value: t }) {
    await (await P()).transaction("preferences", "readwrite").objectStore("preferences").put({ key: e, value: t });
  },
  async deleteCreation({ Pblr: e }) {
    const r = (await P()).transaction("creations", "readwrite").objectStore("creations"), i = await r.index("Pblr").get(e);
    return i && await r.delete(i.Id), !0;
  },
  async renameCreation({ Pblr: e, name: t }) {
    const s = (await P()).transaction("creations", "readwrite").objectStore("creations"), o = await s.index("Pblr").get(e);
    return o && (o.Name = t, await s.put(o)), !0;
  },
  async setApi({ key: e, value: t }) {
    await (await P()).transaction("apis", "readwrite").objectStore("apis").put({ key: e, value: t });
  },
  async getApi({ key: e }) {
    const s = await (await P()).transaction("apis", "readonly").objectStore("apis").get(e);
    return (s == null ? void 0 : s.value) || null;
  },
  async deleteApi({ key: e }) {
    return await (await P()).transaction("apis", "readwrite").objectStore("apis").delete(e), !0;
  }
};
function Ge(e, t) {
  return function() {
    return e.apply(t, arguments);
  };
}
const { toString: Nt } = Object.prototype, { getPrototypeOf: Re } = Object, Y = /* @__PURE__ */ ((e) => (t) => {
  const n = Nt.call(t);
  return e[n] || (e[n] = n.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), C = (e) => (e = e.toLowerCase(), (t) => Y(t) === e), ee = (e) => (t) => typeof t === e, { isArray: U } = Array, H = ee("undefined");
function Lt(e) {
  return e !== null && !H(e) && e.constructor !== null && !H(e.constructor) && A(e.constructor.isBuffer) && e.constructor.isBuffer(e);
}
const Xe = C("ArrayBuffer");
function Ft(e) {
  let t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(e) : t = e && e.buffer && Xe(e.buffer), t;
}
const It = ee("string"), A = ee("function"), Qe = ee("number"), te = (e) => e !== null && typeof e == "object", _t = (e) => e === !0 || e === !1, $ = (e) => {
  if (Y(e) !== "object")
    return !1;
  const t = Re(e);
  return (t === null || t === Object.prototype || Object.getPrototypeOf(t) === null) && !(Symbol.toStringTag in e) && !(Symbol.iterator in e);
}, jt = C("Date"), kt = C("File"), Ut = C("Blob"), Mt = C("FileList"), qt = (e) => te(e) && A(e.pipe), Ht = (e) => {
  let t;
  return e && (typeof FormData == "function" && e instanceof FormData || A(e.append) && ((t = Y(e)) === "formdata" || // detect form-data instance
  t === "object" && A(e.toString) && e.toString() === "[object FormData]"));
}, vt = C("URLSearchParams"), [zt, Vt, Jt, Wt] = ["ReadableStream", "Request", "Response", "Headers"].map(C), $t = (e) => e.trim ? e.trim() : e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function v(e, t, { allOwnKeys: n = !1 } = {}) {
  if (e === null || typeof e > "u")
    return;
  let r, s;
  if (typeof e != "object" && (e = [e]), U(e))
    for (r = 0, s = e.length; r < s; r++)
      t.call(null, e[r], r, e);
  else {
    const i = n ? Object.getOwnPropertyNames(e) : Object.keys(e), o = i.length;
    let c;
    for (r = 0; r < o; r++)
      c = i[r], t.call(null, e[c], c, e);
  }
}
function Ze(e, t) {
  t = t.toLowerCase();
  const n = Object.keys(e);
  let r = n.length, s;
  for (; r-- > 0; )
    if (s = n[r], t === s.toLowerCase())
      return s;
  return null;
}
const I = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global, Ye = (e) => !H(e) && e !== I;
function me() {
  const { caseless: e } = Ye(this) && this || {}, t = {}, n = (r, s) => {
    const i = e && Ze(t, s) || s;
    $(t[i]) && $(r) ? t[i] = me(t[i], r) : $(r) ? t[i] = me({}, r) : U(r) ? t[i] = r.slice() : t[i] = r;
  };
  for (let r = 0, s = arguments.length; r < s; r++)
    arguments[r] && v(arguments[r], n);
  return t;
}
const Kt = (e, t, n, { allOwnKeys: r } = {}) => (v(t, (s, i) => {
  n && A(s) ? e[i] = Ge(s, n) : e[i] = s;
}, { allOwnKeys: r }), e), Gt = (e) => (e.charCodeAt(0) === 65279 && (e = e.slice(1)), e), Xt = (e, t, n, r) => {
  e.prototype = Object.create(t.prototype, r), e.prototype.constructor = e, Object.defineProperty(e, "super", {
    value: t.prototype
  }), n && Object.assign(e.prototype, n);
}, Qt = (e, t, n, r) => {
  let s, i, o;
  const c = {};
  if (t = t || {}, e == null) return t;
  do {
    for (s = Object.getOwnPropertyNames(e), i = s.length; i-- > 0; )
      o = s[i], (!r || r(o, e, t)) && !c[o] && (t[o] = e[o], c[o] = !0);
    e = n !== !1 && Re(e);
  } while (e && (!n || n(e, t)) && e !== Object.prototype);
  return t;
}, Zt = (e, t, n) => {
  e = String(e), (n === void 0 || n > e.length) && (n = e.length), n -= t.length;
  const r = e.indexOf(t, n);
  return r !== -1 && r === n;
}, Yt = (e) => {
  if (!e) return null;
  if (U(e)) return e;
  let t = e.length;
  if (!Qe(t)) return null;
  const n = new Array(t);
  for (; t-- > 0; )
    n[t] = e[t];
  return n;
}, en = /* @__PURE__ */ ((e) => (t) => e && t instanceof e)(typeof Uint8Array < "u" && Re(Uint8Array)), tn = (e, t) => {
  const r = (e && e[Symbol.iterator]).call(e);
  let s;
  for (; (s = r.next()) && !s.done; ) {
    const i = s.value;
    t.call(e, i[0], i[1]);
  }
}, nn = (e, t) => {
  let n;
  const r = [];
  for (; (n = e.exec(t)) !== null; )
    r.push(n);
  return r;
}, rn = C("HTMLFormElement"), sn = (e) => e.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(n, r, s) {
    return r.toUpperCase() + s;
  }
), Fe = (({ hasOwnProperty: e }) => (t, n) => e.call(t, n))(Object.prototype), on = C("RegExp"), et = (e, t) => {
  const n = Object.getOwnPropertyDescriptors(e), r = {};
  v(n, (s, i) => {
    let o;
    (o = t(s, i, e)) !== !1 && (r[i] = o || s);
  }), Object.defineProperties(e, r);
}, an = (e) => {
  et(e, (t, n) => {
    if (A(e) && ["arguments", "caller", "callee"].indexOf(n) !== -1)
      return !1;
    const r = e[n];
    if (A(r)) {
      if (t.enumerable = !1, "writable" in t) {
        t.writable = !1;
        return;
      }
      t.set || (t.set = () => {
        throw Error("Can not rewrite read-only method '" + n + "'");
      });
    }
  });
}, cn = (e, t) => {
  const n = {}, r = (s) => {
    s.forEach((i) => {
      n[i] = !0;
    });
  };
  return U(e) ? r(e) : r(String(e).split(t)), n;
}, un = () => {
}, ln = (e, t) => e != null && Number.isFinite(e = +e) ? e : t, ie = "abcdefghijklmnopqrstuvwxyz", Ie = "0123456789", tt = {
  DIGIT: Ie,
  ALPHA: ie,
  ALPHA_DIGIT: ie + ie.toUpperCase() + Ie
}, fn = (e = 16, t = tt.ALPHA_DIGIT) => {
  let n = "";
  const { length: r } = t;
  for (; e--; )
    n += t[Math.random() * r | 0];
  return n;
};
function dn(e) {
  return !!(e && A(e.append) && e[Symbol.toStringTag] === "FormData" && e[Symbol.iterator]);
}
const pn = (e) => {
  const t = new Array(10), n = (r, s) => {
    if (te(r)) {
      if (t.indexOf(r) >= 0)
        return;
      if (!("toJSON" in r)) {
        t[s] = r;
        const i = U(r) ? [] : {};
        return v(r, (o, c) => {
          const l = n(o, s + 1);
          !H(l) && (i[c] = l);
        }), t[s] = void 0, i;
      }
    }
    return r;
  };
  return n(e, 0);
}, hn = C("AsyncFunction"), mn = (e) => e && (te(e) || A(e)) && A(e.then) && A(e.catch), nt = ((e, t) => e ? setImmediate : t ? ((n, r) => (I.addEventListener("message", ({ source: s, data: i }) => {
  s === I && i === n && r.length && r.shift()();
}, !1), (s) => {
  r.push(s), I.postMessage(n, "*");
}))(`axios@${Math.random()}`, []) : (n) => setTimeout(n))(
  typeof setImmediate == "function",
  A(I.postMessage)
), yn = typeof queueMicrotask < "u" ? queueMicrotask.bind(I) : typeof process < "u" && process.nextTick || nt, a = {
  isArray: U,
  isArrayBuffer: Xe,
  isBuffer: Lt,
  isFormData: Ht,
  isArrayBufferView: Ft,
  isString: It,
  isNumber: Qe,
  isBoolean: _t,
  isObject: te,
  isPlainObject: $,
  isReadableStream: zt,
  isRequest: Vt,
  isResponse: Jt,
  isHeaders: Wt,
  isUndefined: H,
  isDate: jt,
  isFile: kt,
  isBlob: Ut,
  isRegExp: on,
  isFunction: A,
  isStream: qt,
  isURLSearchParams: vt,
  isTypedArray: en,
  isFileList: Mt,
  forEach: v,
  merge: me,
  extend: Kt,
  trim: $t,
  stripBOM: Gt,
  inherits: Xt,
  toFlatObject: Qt,
  kindOf: Y,
  kindOfTest: C,
  endsWith: Zt,
  toArray: Yt,
  forEachEntry: tn,
  matchAll: nn,
  isHTMLForm: rn,
  hasOwnProperty: Fe,
  hasOwnProp: Fe,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: et,
  freezeMethods: an,
  toObjectSet: cn,
  toCamelCase: sn,
  noop: un,
  toFiniteNumber: ln,
  findKey: Ze,
  global: I,
  isContextDefined: Ye,
  ALPHABET: tt,
  generateString: fn,
  isSpecCompliantForm: dn,
  toJSONObject: pn,
  isAsyncFn: hn,
  isThenable: mn,
  setImmediate: nt,
  asap: yn
};
function m(e, t, n, r, s) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = e, this.name = "AxiosError", t && (this.code = t), n && (this.config = n), r && (this.request = r), s && (this.response = s, this.status = s.status ? s.status : null);
}
a.inherits(m, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: a.toJSONObject(this.config),
      code: this.code,
      status: this.status
    };
  }
});
const rt = m.prototype, st = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((e) => {
  st[e] = { value: e };
});
Object.defineProperties(m, st);
Object.defineProperty(rt, "isAxiosError", { value: !0 });
m.from = (e, t, n, r, s, i) => {
  const o = Object.create(rt);
  return a.toFlatObject(e, o, function(l) {
    return l !== Error.prototype;
  }, (c) => c !== "isAxiosError"), m.call(o, e.message, t, n, r, s), o.cause = e, o.name = e.name, i && Object.assign(o, i), o;
};
const bn = null;
function ye(e) {
  return a.isPlainObject(e) || a.isArray(e);
}
function ot(e) {
  return a.endsWith(e, "[]") ? e.slice(0, -2) : e;
}
function _e(e, t, n) {
  return e ? e.concat(t).map(function(s, i) {
    return s = ot(s), !n && i ? "[" + s + "]" : s;
  }).join(n ? "." : "") : t;
}
function wn(e) {
  return a.isArray(e) && !e.some(ye);
}
const gn = a.toFlatObject(a, {}, null, function(t) {
  return /^is[A-Z]/.test(t);
});
function ne(e, t, n) {
  if (!a.isObject(e))
    throw new TypeError("target must be an object");
  t = t || new FormData(), n = a.toFlatObject(n, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function(y, h) {
    return !a.isUndefined(h[y]);
  });
  const r = n.metaTokens, s = n.visitor || f, i = n.dots, o = n.indexes, l = (n.Blob || typeof Blob < "u" && Blob) && a.isSpecCompliantForm(t);
  if (!a.isFunction(s))
    throw new TypeError("visitor must be a function");
  function u(p) {
    if (p === null) return "";
    if (a.isDate(p))
      return p.toISOString();
    if (!l && a.isBlob(p))
      throw new m("Blob is not supported. Use a Buffer instead.");
    return a.isArrayBuffer(p) || a.isTypedArray(p) ? l && typeof Blob == "function" ? new Blob([p]) : Buffer.from(p) : p;
  }
  function f(p, y, h) {
    let w = p;
    if (p && !h && typeof p == "object") {
      if (a.endsWith(y, "{}"))
        y = r ? y : y.slice(0, -2), p = JSON.stringify(p);
      else if (a.isArray(p) && wn(p) || (a.isFileList(p) || a.endsWith(y, "[]")) && (w = a.toArray(p)))
        return y = ot(y), w.forEach(function(R, D) {
          !(a.isUndefined(R) || R === null) && t.append(
            // eslint-disable-next-line no-nested-ternary
            o === !0 ? _e([y], D, i) : o === null ? y : y + "[]",
            u(R)
          );
        }), !1;
    }
    return ye(p) ? !0 : (t.append(_e(h, y, i), u(p)), !1);
  }
  const d = [], b = Object.assign(gn, {
    defaultVisitor: f,
    convertValue: u,
    isVisitable: ye
  });
  function E(p, y) {
    if (!a.isUndefined(p)) {
      if (d.indexOf(p) !== -1)
        throw Error("Circular reference detected in " + y.join("."));
      d.push(p), a.forEach(p, function(w, S) {
        (!(a.isUndefined(w) || w === null) && s.call(
          t,
          w,
          a.isString(S) ? S.trim() : S,
          y,
          b
        )) === !0 && E(w, y ? y.concat(S) : [S]);
      }), d.pop();
    }
  }
  if (!a.isObject(e))
    throw new TypeError("data must be an object");
  return E(e), t;
}
function je(e) {
  const t = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(e).replace(/[!'()~]|%20|%00/g, function(r) {
    return t[r];
  });
}
function Oe(e, t) {
  this._pairs = [], e && ne(e, this, t);
}
const it = Oe.prototype;
it.append = function(t, n) {
  this._pairs.push([t, n]);
};
it.toString = function(t) {
  const n = t ? function(r) {
    return t.call(this, r, je);
  } : je;
  return this._pairs.map(function(s) {
    return n(s[0]) + "=" + n(s[1]);
  }, "").join("&");
};
function En(e) {
  return encodeURIComponent(e).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function at(e, t, n) {
  if (!t)
    return e;
  const r = n && n.encode || En, s = n && n.serialize;
  let i;
  if (s ? i = s(t, n) : i = a.isURLSearchParams(t) ? t.toString() : new Oe(t, n).toString(r), i) {
    const o = e.indexOf("#");
    o !== -1 && (e = e.slice(0, o)), e += (e.indexOf("?") === -1 ? "?" : "&") + i;
  }
  return e;
}
class ke {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(t, n, r) {
    return this.handlers.push({
      fulfilled: t,
      rejected: n,
      synchronous: r ? r.synchronous : !1,
      runWhen: r ? r.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(t) {
    this.handlers[t] && (this.handlers[t] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(t) {
    a.forEach(this.handlers, function(r) {
      r !== null && t(r);
    });
  }
}
const ct = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, Sn = typeof URLSearchParams < "u" ? URLSearchParams : Oe, Rn = typeof FormData < "u" ? FormData : null, On = typeof Blob < "u" ? Blob : null, Tn = {
  isBrowser: !0,
  classes: {
    URLSearchParams: Sn,
    FormData: Rn,
    Blob: On
  },
  protocols: ["http", "https", "file", "blob", "url", "data"]
}, Te = typeof window < "u" && typeof document < "u", be = typeof navigator == "object" && navigator || void 0, xn = Te && (!be || ["ReactNative", "NativeScript", "NS"].indexOf(be.product) < 0), An = typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function", Cn = Te && window.location.href || "http://localhost", Pn = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hasBrowserEnv: Te,
  hasStandardBrowserEnv: xn,
  hasStandardBrowserWebWorkerEnv: An,
  navigator: be,
  origin: Cn
}, Symbol.toStringTag, { value: "Module" })), T = {
  ...Pn,
  ...Tn
};
function Dn(e, t) {
  return ne(e, new T.classes.URLSearchParams(), Object.assign({
    visitor: function(n, r, s, i) {
      return T.isNode && a.isBuffer(n) ? (this.append(r, n.toString("base64")), !1) : i.defaultVisitor.apply(this, arguments);
    }
  }, t));
}
function Bn(e) {
  return a.matchAll(/\w+|\[(\w*)]/g, e).map((t) => t[0] === "[]" ? "" : t[1] || t[0]);
}
function Nn(e) {
  const t = {}, n = Object.keys(e);
  let r;
  const s = n.length;
  let i;
  for (r = 0; r < s; r++)
    i = n[r], t[i] = e[i];
  return t;
}
function ut(e) {
  function t(n, r, s, i) {
    let o = n[i++];
    if (o === "__proto__") return !0;
    const c = Number.isFinite(+o), l = i >= n.length;
    return o = !o && a.isArray(s) ? s.length : o, l ? (a.hasOwnProp(s, o) ? s[o] = [s[o], r] : s[o] = r, !c) : ((!s[o] || !a.isObject(s[o])) && (s[o] = []), t(n, r, s[o], i) && a.isArray(s[o]) && (s[o] = Nn(s[o])), !c);
  }
  if (a.isFormData(e) && a.isFunction(e.entries)) {
    const n = {};
    return a.forEachEntry(e, (r, s) => {
      t(Bn(r), s, n, 0);
    }), n;
  }
  return null;
}
function Ln(e, t, n) {
  if (a.isString(e))
    try {
      return (t || JSON.parse)(e), a.trim(e);
    } catch (r) {
      if (r.name !== "SyntaxError")
        throw r;
    }
  return (n || JSON.stringify)(e);
}
const z = {
  transitional: ct,
  adapter: ["xhr", "http", "fetch"],
  transformRequest: [function(t, n) {
    const r = n.getContentType() || "", s = r.indexOf("application/json") > -1, i = a.isObject(t);
    if (i && a.isHTMLForm(t) && (t = new FormData(t)), a.isFormData(t))
      return s ? JSON.stringify(ut(t)) : t;
    if (a.isArrayBuffer(t) || a.isBuffer(t) || a.isStream(t) || a.isFile(t) || a.isBlob(t) || a.isReadableStream(t))
      return t;
    if (a.isArrayBufferView(t))
      return t.buffer;
    if (a.isURLSearchParams(t))
      return n.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), t.toString();
    let c;
    if (i) {
      if (r.indexOf("application/x-www-form-urlencoded") > -1)
        return Dn(t, this.formSerializer).toString();
      if ((c = a.isFileList(t)) || r.indexOf("multipart/form-data") > -1) {
        const l = this.env && this.env.FormData;
        return ne(
          c ? { "files[]": t } : t,
          l && new l(),
          this.formSerializer
        );
      }
    }
    return i || s ? (n.setContentType("application/json", !1), Ln(t)) : t;
  }],
  transformResponse: [function(t) {
    const n = this.transitional || z.transitional, r = n && n.forcedJSONParsing, s = this.responseType === "json";
    if (a.isResponse(t) || a.isReadableStream(t))
      return t;
    if (t && a.isString(t) && (r && !this.responseType || s)) {
      const o = !(n && n.silentJSONParsing) && s;
      try {
        return JSON.parse(t);
      } catch (c) {
        if (o)
          throw c.name === "SyntaxError" ? m.from(c, m.ERR_BAD_RESPONSE, this, null, this.response) : c;
      }
    }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: T.classes.FormData,
    Blob: T.classes.Blob
  },
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
a.forEach(["delete", "get", "head", "post", "put", "patch"], (e) => {
  z.headers[e] = {};
});
const Fn = a.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), In = (e) => {
  const t = {};
  let n, r, s;
  return e && e.split(`
`).forEach(function(o) {
    s = o.indexOf(":"), n = o.substring(0, s).trim().toLowerCase(), r = o.substring(s + 1).trim(), !(!n || t[n] && Fn[n]) && (n === "set-cookie" ? t[n] ? t[n].push(r) : t[n] = [r] : t[n] = t[n] ? t[n] + ", " + r : r);
  }), t;
}, Ue = Symbol("internals");
function q(e) {
  return e && String(e).trim().toLowerCase();
}
function K(e) {
  return e === !1 || e == null ? e : a.isArray(e) ? e.map(K) : String(e);
}
function _n(e) {
  const t = /* @__PURE__ */ Object.create(null), n = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let r;
  for (; r = n.exec(e); )
    t[r[1]] = r[2];
  return t;
}
const jn = (e) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(e.trim());
function ae(e, t, n, r, s) {
  if (a.isFunction(r))
    return r.call(this, t, n);
  if (s && (t = n), !!a.isString(t)) {
    if (a.isString(r))
      return t.indexOf(r) !== -1;
    if (a.isRegExp(r))
      return r.test(t);
  }
}
function kn(e) {
  return e.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (t, n, r) => n.toUpperCase() + r);
}
function Un(e, t) {
  const n = a.toCamelCase(" " + t);
  ["get", "set", "has"].forEach((r) => {
    Object.defineProperty(e, r + n, {
      value: function(s, i, o) {
        return this[r].call(this, t, s, i, o);
      },
      configurable: !0
    });
  });
}
class x {
  constructor(t) {
    t && this.set(t);
  }
  set(t, n, r) {
    const s = this;
    function i(c, l, u) {
      const f = q(l);
      if (!f)
        throw new Error("header name must be a non-empty string");
      const d = a.findKey(s, f);
      (!d || s[d] === void 0 || u === !0 || u === void 0 && s[d] !== !1) && (s[d || l] = K(c));
    }
    const o = (c, l) => a.forEach(c, (u, f) => i(u, f, l));
    if (a.isPlainObject(t) || t instanceof this.constructor)
      o(t, n);
    else if (a.isString(t) && (t = t.trim()) && !jn(t))
      o(In(t), n);
    else if (a.isHeaders(t))
      for (const [c, l] of t.entries())
        i(l, c, r);
    else
      t != null && i(n, t, r);
    return this;
  }
  get(t, n) {
    if (t = q(t), t) {
      const r = a.findKey(this, t);
      if (r) {
        const s = this[r];
        if (!n)
          return s;
        if (n === !0)
          return _n(s);
        if (a.isFunction(n))
          return n.call(this, s, r);
        if (a.isRegExp(n))
          return n.exec(s);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(t, n) {
    if (t = q(t), t) {
      const r = a.findKey(this, t);
      return !!(r && this[r] !== void 0 && (!n || ae(this, this[r], r, n)));
    }
    return !1;
  }
  delete(t, n) {
    const r = this;
    let s = !1;
    function i(o) {
      if (o = q(o), o) {
        const c = a.findKey(r, o);
        c && (!n || ae(r, r[c], c, n)) && (delete r[c], s = !0);
      }
    }
    return a.isArray(t) ? t.forEach(i) : i(t), s;
  }
  clear(t) {
    const n = Object.keys(this);
    let r = n.length, s = !1;
    for (; r--; ) {
      const i = n[r];
      (!t || ae(this, this[i], i, t, !0)) && (delete this[i], s = !0);
    }
    return s;
  }
  normalize(t) {
    const n = this, r = {};
    return a.forEach(this, (s, i) => {
      const o = a.findKey(r, i);
      if (o) {
        n[o] = K(s), delete n[i];
        return;
      }
      const c = t ? kn(i) : String(i).trim();
      c !== i && delete n[i], n[c] = K(s), r[c] = !0;
    }), this;
  }
  concat(...t) {
    return this.constructor.concat(this, ...t);
  }
  toJSON(t) {
    const n = /* @__PURE__ */ Object.create(null);
    return a.forEach(this, (r, s) => {
      r != null && r !== !1 && (n[s] = t && a.isArray(r) ? r.join(", ") : r);
    }), n;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([t, n]) => t + ": " + n).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(t) {
    return t instanceof this ? t : new this(t);
  }
  static concat(t, ...n) {
    const r = new this(t);
    return n.forEach((s) => r.set(s)), r;
  }
  static accessor(t) {
    const r = (this[Ue] = this[Ue] = {
      accessors: {}
    }).accessors, s = this.prototype;
    function i(o) {
      const c = q(o);
      r[c] || (Un(s, o), r[c] = !0);
    }
    return a.isArray(t) ? t.forEach(i) : i(t), this;
  }
}
x.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
a.reduceDescriptors(x.prototype, ({ value: e }, t) => {
  let n = t[0].toUpperCase() + t.slice(1);
  return {
    get: () => e,
    set(r) {
      this[n] = r;
    }
  };
});
a.freezeMethods(x);
function ce(e, t) {
  const n = this || z, r = t || n, s = x.from(r.headers);
  let i = r.data;
  return a.forEach(e, function(c) {
    i = c.call(n, i, s.normalize(), t ? t.status : void 0);
  }), s.normalize(), i;
}
function lt(e) {
  return !!(e && e.__CANCEL__);
}
function M(e, t, n) {
  m.call(this, e ?? "canceled", m.ERR_CANCELED, t, n), this.name = "CanceledError";
}
a.inherits(M, m, {
  __CANCEL__: !0
});
function ft(e, t, n) {
  const r = n.config.validateStatus;
  !n.status || !r || r(n.status) ? e(n) : t(new m(
    "Request failed with status code " + n.status,
    [m.ERR_BAD_REQUEST, m.ERR_BAD_RESPONSE][Math.floor(n.status / 100) - 4],
    n.config,
    n.request,
    n
  ));
}
function Mn(e) {
  const t = /^([-+\w]{1,25})(:?\/\/|:)/.exec(e);
  return t && t[1] || "";
}
function qn(e, t) {
  e = e || 10;
  const n = new Array(e), r = new Array(e);
  let s = 0, i = 0, o;
  return t = t !== void 0 ? t : 1e3, function(l) {
    const u = Date.now(), f = r[i];
    o || (o = u), n[s] = l, r[s] = u;
    let d = i, b = 0;
    for (; d !== s; )
      b += n[d++], d = d % e;
    if (s = (s + 1) % e, s === i && (i = (i + 1) % e), u - o < t)
      return;
    const E = f && u - f;
    return E ? Math.round(b * 1e3 / E) : void 0;
  };
}
function Hn(e, t) {
  let n = 0, r = 1e3 / t, s, i;
  const o = (u, f = Date.now()) => {
    n = f, s = null, i && (clearTimeout(i), i = null), e.apply(null, u);
  };
  return [(...u) => {
    const f = Date.now(), d = f - n;
    d >= r ? o(u, f) : (s = u, i || (i = setTimeout(() => {
      i = null, o(s);
    }, r - d)));
  }, () => s && o(s)];
}
const X = (e, t, n = 3) => {
  let r = 0;
  const s = qn(50, 250);
  return Hn((i) => {
    const o = i.loaded, c = i.lengthComputable ? i.total : void 0, l = o - r, u = s(l), f = o <= c;
    r = o;
    const d = {
      loaded: o,
      total: c,
      progress: c ? o / c : void 0,
      bytes: l,
      rate: u || void 0,
      estimated: u && c && f ? (c - o) / u : void 0,
      event: i,
      lengthComputable: c != null,
      [t ? "download" : "upload"]: !0
    };
    e(d);
  }, n);
}, Me = (e, t) => {
  const n = e != null;
  return [(r) => t[0]({
    lengthComputable: n,
    total: e,
    loaded: r
  }), t[1]];
}, qe = (e) => (...t) => a.asap(() => e(...t)), vn = T.hasStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const t = T.navigator && /(msie|trident)/i.test(T.navigator.userAgent), n = document.createElement("a");
    let r;
    function s(i) {
      let o = i;
      return t && (n.setAttribute("href", o), o = n.href), n.setAttribute("href", o), {
        href: n.href,
        protocol: n.protocol ? n.protocol.replace(/:$/, "") : "",
        host: n.host,
        search: n.search ? n.search.replace(/^\?/, "") : "",
        hash: n.hash ? n.hash.replace(/^#/, "") : "",
        hostname: n.hostname,
        port: n.port,
        pathname: n.pathname.charAt(0) === "/" ? n.pathname : "/" + n.pathname
      };
    }
    return r = s(window.location.href), function(o) {
      const c = a.isString(o) ? s(o) : o;
      return c.protocol === r.protocol && c.host === r.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  /* @__PURE__ */ function() {
    return function() {
      return !0;
    };
  }()
), zn = T.hasStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  {
    write(e, t, n, r, s, i) {
      const o = [e + "=" + encodeURIComponent(t)];
      a.isNumber(n) && o.push("expires=" + new Date(n).toGMTString()), a.isString(r) && o.push("path=" + r), a.isString(s) && o.push("domain=" + s), i === !0 && o.push("secure"), document.cookie = o.join("; ");
    },
    read(e) {
      const t = document.cookie.match(new RegExp("(^|;\\s*)(" + e + ")=([^;]*)"));
      return t ? decodeURIComponent(t[3]) : null;
    },
    remove(e) {
      this.write(e, "", Date.now() - 864e5);
    }
  }
) : (
  // Non-standard browser env (web workers, react-native) lack needed support.
  {
    write() {
    },
    read() {
      return null;
    },
    remove() {
    }
  }
);
function Vn(e) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(e);
}
function Jn(e, t) {
  return t ? e.replace(/\/?\/$/, "") + "/" + t.replace(/^\/+/, "") : e;
}
function dt(e, t) {
  return e && !Vn(t) ? Jn(e, t) : t;
}
const He = (e) => e instanceof x ? { ...e } : e;
function k(e, t) {
  t = t || {};
  const n = {};
  function r(u, f, d) {
    return a.isPlainObject(u) && a.isPlainObject(f) ? a.merge.call({ caseless: d }, u, f) : a.isPlainObject(f) ? a.merge({}, f) : a.isArray(f) ? f.slice() : f;
  }
  function s(u, f, d) {
    if (a.isUndefined(f)) {
      if (!a.isUndefined(u))
        return r(void 0, u, d);
    } else return r(u, f, d);
  }
  function i(u, f) {
    if (!a.isUndefined(f))
      return r(void 0, f);
  }
  function o(u, f) {
    if (a.isUndefined(f)) {
      if (!a.isUndefined(u))
        return r(void 0, u);
    } else return r(void 0, f);
  }
  function c(u, f, d) {
    if (d in t)
      return r(u, f);
    if (d in e)
      return r(void 0, u);
  }
  const l = {
    url: i,
    method: i,
    data: i,
    baseURL: o,
    transformRequest: o,
    transformResponse: o,
    paramsSerializer: o,
    timeout: o,
    timeoutMessage: o,
    withCredentials: o,
    withXSRFToken: o,
    adapter: o,
    responseType: o,
    xsrfCookieName: o,
    xsrfHeaderName: o,
    onUploadProgress: o,
    onDownloadProgress: o,
    decompress: o,
    maxContentLength: o,
    maxBodyLength: o,
    beforeRedirect: o,
    transport: o,
    httpAgent: o,
    httpsAgent: o,
    cancelToken: o,
    socketPath: o,
    responseEncoding: o,
    validateStatus: c,
    headers: (u, f) => s(He(u), He(f), !0)
  };
  return a.forEach(Object.keys(Object.assign({}, e, t)), function(f) {
    const d = l[f] || s, b = d(e[f], t[f], f);
    a.isUndefined(b) && d !== c || (n[f] = b);
  }), n;
}
const pt = (e) => {
  const t = k({}, e);
  let { data: n, withXSRFToken: r, xsrfHeaderName: s, xsrfCookieName: i, headers: o, auth: c } = t;
  t.headers = o = x.from(o), t.url = at(dt(t.baseURL, t.url), e.params, e.paramsSerializer), c && o.set(
    "Authorization",
    "Basic " + btoa((c.username || "") + ":" + (c.password ? unescape(encodeURIComponent(c.password)) : ""))
  );
  let l;
  if (a.isFormData(n)) {
    if (T.hasStandardBrowserEnv || T.hasStandardBrowserWebWorkerEnv)
      o.setContentType(void 0);
    else if ((l = o.getContentType()) !== !1) {
      const [u, ...f] = l ? l.split(";").map((d) => d.trim()).filter(Boolean) : [];
      o.setContentType([u || "multipart/form-data", ...f].join("; "));
    }
  }
  if (T.hasStandardBrowserEnv && (r && a.isFunction(r) && (r = r(t)), r || r !== !1 && vn(t.url))) {
    const u = s && i && zn.read(i);
    u && o.set(s, u);
  }
  return t;
}, Wn = typeof XMLHttpRequest < "u", $n = Wn && function(e) {
  return new Promise(function(n, r) {
    const s = pt(e);
    let i = s.data;
    const o = x.from(s.headers).normalize();
    let { responseType: c, onUploadProgress: l, onDownloadProgress: u } = s, f, d, b, E, p;
    function y() {
      E && E(), p && p(), s.cancelToken && s.cancelToken.unsubscribe(f), s.signal && s.signal.removeEventListener("abort", f);
    }
    let h = new XMLHttpRequest();
    h.open(s.method.toUpperCase(), s.url, !0), h.timeout = s.timeout;
    function w() {
      if (!h)
        return;
      const R = x.from(
        "getAllResponseHeaders" in h && h.getAllResponseHeaders()
      ), O = {
        data: !c || c === "text" || c === "json" ? h.responseText : h.response,
        status: h.status,
        statusText: h.statusText,
        headers: R,
        config: e,
        request: h
      };
      ft(function(F) {
        n(F), y();
      }, function(F) {
        r(F), y();
      }, O), h = null;
    }
    "onloadend" in h ? h.onloadend = w : h.onreadystatechange = function() {
      !h || h.readyState !== 4 || h.status === 0 && !(h.responseURL && h.responseURL.indexOf("file:") === 0) || setTimeout(w);
    }, h.onabort = function() {
      h && (r(new m("Request aborted", m.ECONNABORTED, e, h)), h = null);
    }, h.onerror = function() {
      r(new m("Network Error", m.ERR_NETWORK, e, h)), h = null;
    }, h.ontimeout = function() {
      let D = s.timeout ? "timeout of " + s.timeout + "ms exceeded" : "timeout exceeded";
      const O = s.transitional || ct;
      s.timeoutErrorMessage && (D = s.timeoutErrorMessage), r(new m(
        D,
        O.clarifyTimeoutError ? m.ETIMEDOUT : m.ECONNABORTED,
        e,
        h
      )), h = null;
    }, i === void 0 && o.setContentType(null), "setRequestHeader" in h && a.forEach(o.toJSON(), function(D, O) {
      h.setRequestHeader(O, D);
    }), a.isUndefined(s.withCredentials) || (h.withCredentials = !!s.withCredentials), c && c !== "json" && (h.responseType = s.responseType), u && ([b, p] = X(u, !0), h.addEventListener("progress", b)), l && h.upload && ([d, E] = X(l), h.upload.addEventListener("progress", d), h.upload.addEventListener("loadend", E)), (s.cancelToken || s.signal) && (f = (R) => {
      h && (r(!R || R.type ? new M(null, e, h) : R), h.abort(), h = null);
    }, s.cancelToken && s.cancelToken.subscribe(f), s.signal && (s.signal.aborted ? f() : s.signal.addEventListener("abort", f)));
    const S = Mn(s.url);
    if (S && T.protocols.indexOf(S) === -1) {
      r(new m("Unsupported protocol " + S + ":", m.ERR_BAD_REQUEST, e));
      return;
    }
    h.send(i || null);
  });
}, Kn = (e, t) => {
  const { length: n } = e = e ? e.filter(Boolean) : [];
  if (t || n) {
    let r = new AbortController(), s;
    const i = function(u) {
      if (!s) {
        s = !0, c();
        const f = u instanceof Error ? u : this.reason;
        r.abort(f instanceof m ? f : new M(f instanceof Error ? f.message : f));
      }
    };
    let o = t && setTimeout(() => {
      o = null, i(new m(`timeout ${t} of ms exceeded`, m.ETIMEDOUT));
    }, t);
    const c = () => {
      e && (o && clearTimeout(o), o = null, e.forEach((u) => {
        u.unsubscribe ? u.unsubscribe(i) : u.removeEventListener("abort", i);
      }), e = null);
    };
    e.forEach((u) => u.addEventListener("abort", i));
    const { signal: l } = r;
    return l.unsubscribe = () => a.asap(c), l;
  }
}, Gn = function* (e, t) {
  let n = e.byteLength;
  if (!t || n < t) {
    yield e;
    return;
  }
  let r = 0, s;
  for (; r < n; )
    s = r + t, yield e.slice(r, s), r = s;
}, Xn = async function* (e, t) {
  for await (const n of Qn(e))
    yield* Gn(n, t);
}, Qn = async function* (e) {
  if (e[Symbol.asyncIterator]) {
    yield* e;
    return;
  }
  const t = e.getReader();
  try {
    for (; ; ) {
      const { done: n, value: r } = await t.read();
      if (n)
        break;
      yield r;
    }
  } finally {
    await t.cancel();
  }
}, ve = (e, t, n, r) => {
  const s = Xn(e, t);
  let i = 0, o, c = (l) => {
    o || (o = !0, r && r(l));
  };
  return new ReadableStream({
    async pull(l) {
      try {
        const { done: u, value: f } = await s.next();
        if (u) {
          c(), l.close();
          return;
        }
        let d = f.byteLength;
        if (n) {
          let b = i += d;
          n(b);
        }
        l.enqueue(new Uint8Array(f));
      } catch (u) {
        throw c(u), u;
      }
    },
    cancel(l) {
      return c(l), s.return();
    }
  }, {
    highWaterMark: 2
  });
}, re = typeof fetch == "function" && typeof Request == "function" && typeof Response == "function", ht = re && typeof ReadableStream == "function", Zn = re && (typeof TextEncoder == "function" ? /* @__PURE__ */ ((e) => (t) => e.encode(t))(new TextEncoder()) : async (e) => new Uint8Array(await new Response(e).arrayBuffer())), mt = (e, ...t) => {
  try {
    return !!e(...t);
  } catch {
    return !1;
  }
}, Yn = ht && mt(() => {
  let e = !1;
  const t = new Request(T.origin, {
    body: new ReadableStream(),
    method: "POST",
    get duplex() {
      return e = !0, "half";
    }
  }).headers.has("Content-Type");
  return e && !t;
}), ze = 64 * 1024, we = ht && mt(() => a.isReadableStream(new Response("").body)), Q = {
  stream: we && ((e) => e.body)
};
re && ((e) => {
  ["text", "arrayBuffer", "blob", "formData", "stream"].forEach((t) => {
    !Q[t] && (Q[t] = a.isFunction(e[t]) ? (n) => n[t]() : (n, r) => {
      throw new m(`Response type '${t}' is not supported`, m.ERR_NOT_SUPPORT, r);
    });
  });
})(new Response());
const er = async (e) => {
  if (e == null)
    return 0;
  if (a.isBlob(e))
    return e.size;
  if (a.isSpecCompliantForm(e))
    return (await new Request(T.origin, {
      method: "POST",
      body: e
    }).arrayBuffer()).byteLength;
  if (a.isArrayBufferView(e) || a.isArrayBuffer(e))
    return e.byteLength;
  if (a.isURLSearchParams(e) && (e = e + ""), a.isString(e))
    return (await Zn(e)).byteLength;
}, tr = async (e, t) => {
  const n = a.toFiniteNumber(e.getContentLength());
  return n ?? er(t);
}, nr = re && (async (e) => {
  let {
    url: t,
    method: n,
    data: r,
    signal: s,
    cancelToken: i,
    timeout: o,
    onDownloadProgress: c,
    onUploadProgress: l,
    responseType: u,
    headers: f,
    withCredentials: d = "same-origin",
    fetchOptions: b
  } = pt(e);
  u = u ? (u + "").toLowerCase() : "text";
  let E = Kn([s, i && i.toAbortSignal()], o), p;
  const y = E && E.unsubscribe && (() => {
    E.unsubscribe();
  });
  let h;
  try {
    if (l && Yn && n !== "get" && n !== "head" && (h = await tr(f, r)) !== 0) {
      let O = new Request(t, {
        method: "POST",
        body: r,
        duplex: "half"
      }), B;
      if (a.isFormData(r) && (B = O.headers.get("content-type")) && f.setContentType(B), O.body) {
        const [F, V] = Me(
          h,
          X(qe(l))
        );
        r = ve(O.body, ze, F, V);
      }
    }
    a.isString(d) || (d = d ? "include" : "omit");
    const w = "credentials" in Request.prototype;
    p = new Request(t, {
      ...b,
      signal: E,
      method: n.toUpperCase(),
      headers: f.normalize().toJSON(),
      body: r,
      duplex: "half",
      credentials: w ? d : void 0
    });
    let S = await fetch(p);
    const R = we && (u === "stream" || u === "response");
    if (we && (c || R && y)) {
      const O = {};
      ["status", "statusText", "headers"].forEach((Ce) => {
        O[Ce] = S[Ce];
      });
      const B = a.toFiniteNumber(S.headers.get("content-length")), [F, V] = c && Me(
        B,
        X(qe(c), !0)
      ) || [];
      S = new Response(
        ve(S.body, ze, F, () => {
          V && V(), y && y();
        }),
        O
      );
    }
    u = u || "text";
    let D = await Q[a.findKey(Q, u) || "text"](S, e);
    return !R && y && y(), await new Promise((O, B) => {
      ft(O, B, {
        data: D,
        headers: x.from(S.headers),
        status: S.status,
        statusText: S.statusText,
        config: e,
        request: p
      });
    });
  } catch (w) {
    throw y && y(), w && w.name === "TypeError" && /fetch/i.test(w.message) ? Object.assign(
      new m("Network Error", m.ERR_NETWORK, e, p),
      {
        cause: w.cause || w
      }
    ) : m.from(w, w && w.code, e, p);
  }
}), ge = {
  http: bn,
  xhr: $n,
  fetch: nr
};
a.forEach(ge, (e, t) => {
  if (e) {
    try {
      Object.defineProperty(e, "name", { value: t });
    } catch {
    }
    Object.defineProperty(e, "adapterName", { value: t });
  }
});
const Ve = (e) => `- ${e}`, rr = (e) => a.isFunction(e) || e === null || e === !1, yt = {
  getAdapter: (e) => {
    e = a.isArray(e) ? e : [e];
    const { length: t } = e;
    let n, r;
    const s = {};
    for (let i = 0; i < t; i++) {
      n = e[i];
      let o;
      if (r = n, !rr(n) && (r = ge[(o = String(n)).toLowerCase()], r === void 0))
        throw new m(`Unknown adapter '${o}'`);
      if (r)
        break;
      s[o || "#" + i] = r;
    }
    if (!r) {
      const i = Object.entries(s).map(
        ([c, l]) => `adapter ${c} ` + (l === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let o = t ? i.length > 1 ? `since :
` + i.map(Ve).join(`
`) : " " + Ve(i[0]) : "as no adapter specified";
      throw new m(
        "There is no suitable adapter to dispatch the request " + o,
        "ERR_NOT_SUPPORT"
      );
    }
    return r;
  },
  adapters: ge
};
function ue(e) {
  if (e.cancelToken && e.cancelToken.throwIfRequested(), e.signal && e.signal.aborted)
    throw new M(null, e);
}
function Je(e) {
  return ue(e), e.headers = x.from(e.headers), e.data = ce.call(
    e,
    e.transformRequest
  ), ["post", "put", "patch"].indexOf(e.method) !== -1 && e.headers.setContentType("application/x-www-form-urlencoded", !1), yt.getAdapter(e.adapter || z.adapter)(e).then(function(r) {
    return ue(e), r.data = ce.call(
      e,
      e.transformResponse,
      r
    ), r.headers = x.from(r.headers), r;
  }, function(r) {
    return lt(r) || (ue(e), r && r.response && (r.response.data = ce.call(
      e,
      e.transformResponse,
      r.response
    ), r.response.headers = x.from(r.response.headers))), Promise.reject(r);
  });
}
const bt = "1.7.7", xe = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((e, t) => {
  xe[e] = function(r) {
    return typeof r === e || "a" + (t < 1 ? "n " : " ") + e;
  };
});
const We = {};
xe.transitional = function(t, n, r) {
  function s(i, o) {
    return "[Axios v" + bt + "] Transitional option '" + i + "'" + o + (r ? ". " + r : "");
  }
  return (i, o, c) => {
    if (t === !1)
      throw new m(
        s(o, " has been removed" + (n ? " in " + n : "")),
        m.ERR_DEPRECATED
      );
    return n && !We[o] && (We[o] = !0, console.warn(
      s(
        o,
        " has been deprecated since v" + n + " and will be removed in the near future"
      )
    )), t ? t(i, o, c) : !0;
  };
};
function sr(e, t, n) {
  if (typeof e != "object")
    throw new m("options must be an object", m.ERR_BAD_OPTION_VALUE);
  const r = Object.keys(e);
  let s = r.length;
  for (; s-- > 0; ) {
    const i = r[s], o = t[i];
    if (o) {
      const c = e[i], l = c === void 0 || o(c, i, e);
      if (l !== !0)
        throw new m("option " + i + " must be " + l, m.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (n !== !0)
      throw new m("Unknown option " + i, m.ERR_BAD_OPTION);
  }
}
const Ee = {
  assertOptions: sr,
  validators: xe
}, L = Ee.validators;
class j {
  constructor(t) {
    this.defaults = t, this.interceptors = {
      request: new ke(),
      response: new ke()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  async request(t, n) {
    try {
      return await this._request(t, n);
    } catch (r) {
      if (r instanceof Error) {
        let s;
        Error.captureStackTrace ? Error.captureStackTrace(s = {}) : s = new Error();
        const i = s.stack ? s.stack.replace(/^.+\n/, "") : "";
        try {
          r.stack ? i && !String(r.stack).endsWith(i.replace(/^.+\n.+\n/, "")) && (r.stack += `
` + i) : r.stack = i;
        } catch {
        }
      }
      throw r;
    }
  }
  _request(t, n) {
    typeof t == "string" ? (n = n || {}, n.url = t) : n = t || {}, n = k(this.defaults, n);
    const { transitional: r, paramsSerializer: s, headers: i } = n;
    r !== void 0 && Ee.assertOptions(r, {
      silentJSONParsing: L.transitional(L.boolean),
      forcedJSONParsing: L.transitional(L.boolean),
      clarifyTimeoutError: L.transitional(L.boolean)
    }, !1), s != null && (a.isFunction(s) ? n.paramsSerializer = {
      serialize: s
    } : Ee.assertOptions(s, {
      encode: L.function,
      serialize: L.function
    }, !0)), n.method = (n.method || this.defaults.method || "get").toLowerCase();
    let o = i && a.merge(
      i.common,
      i[n.method]
    );
    i && a.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (p) => {
        delete i[p];
      }
    ), n.headers = x.concat(o, i);
    const c = [];
    let l = !0;
    this.interceptors.request.forEach(function(y) {
      typeof y.runWhen == "function" && y.runWhen(n) === !1 || (l = l && y.synchronous, c.unshift(y.fulfilled, y.rejected));
    });
    const u = [];
    this.interceptors.response.forEach(function(y) {
      u.push(y.fulfilled, y.rejected);
    });
    let f, d = 0, b;
    if (!l) {
      const p = [Je.bind(this), void 0];
      for (p.unshift.apply(p, c), p.push.apply(p, u), b = p.length, f = Promise.resolve(n); d < b; )
        f = f.then(p[d++], p[d++]);
      return f;
    }
    b = c.length;
    let E = n;
    for (d = 0; d < b; ) {
      const p = c[d++], y = c[d++];
      try {
        E = p(E);
      } catch (h) {
        y.call(this, h);
        break;
      }
    }
    try {
      f = Je.call(this, E);
    } catch (p) {
      return Promise.reject(p);
    }
    for (d = 0, b = u.length; d < b; )
      f = f.then(u[d++], u[d++]);
    return f;
  }
  getUri(t) {
    t = k(this.defaults, t);
    const n = dt(t.baseURL, t.url);
    return at(n, t.params, t.paramsSerializer);
  }
}
a.forEach(["delete", "get", "head", "options"], function(t) {
  j.prototype[t] = function(n, r) {
    return this.request(k(r || {}, {
      method: t,
      url: n,
      data: (r || {}).data
    }));
  };
});
a.forEach(["post", "put", "patch"], function(t) {
  function n(r) {
    return function(i, o, c) {
      return this.request(k(c || {}, {
        method: t,
        headers: r ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: i,
        data: o
      }));
    };
  }
  j.prototype[t] = n(), j.prototype[t + "Form"] = n(!0);
});
class Ae {
  constructor(t) {
    if (typeof t != "function")
      throw new TypeError("executor must be a function.");
    let n;
    this.promise = new Promise(function(i) {
      n = i;
    });
    const r = this;
    this.promise.then((s) => {
      if (!r._listeners) return;
      let i = r._listeners.length;
      for (; i-- > 0; )
        r._listeners[i](s);
      r._listeners = null;
    }), this.promise.then = (s) => {
      let i;
      const o = new Promise((c) => {
        r.subscribe(c), i = c;
      }).then(s);
      return o.cancel = function() {
        r.unsubscribe(i);
      }, o;
    }, t(function(i, o, c) {
      r.reason || (r.reason = new M(i, o, c), n(r.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(t) {
    if (this.reason) {
      t(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(t) : this._listeners = [t];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(t) {
    if (!this._listeners)
      return;
    const n = this._listeners.indexOf(t);
    n !== -1 && this._listeners.splice(n, 1);
  }
  toAbortSignal() {
    const t = new AbortController(), n = (r) => {
      t.abort(r);
    };
    return this.subscribe(n), t.signal.unsubscribe = () => this.unsubscribe(n), t.signal;
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let t;
    return {
      token: new Ae(function(s) {
        t = s;
      }),
      cancel: t
    };
  }
}
function or(e) {
  return function(n) {
    return e.apply(null, n);
  };
}
function ir(e) {
  return a.isObject(e) && e.isAxiosError === !0;
}
const Se = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(Se).forEach(([e, t]) => {
  Se[t] = e;
});
function wt(e) {
  const t = new j(e), n = Ge(j.prototype.request, t);
  return a.extend(n, j.prototype, t, { allOwnKeys: !0 }), a.extend(n, t, null, { allOwnKeys: !0 }), n.create = function(s) {
    return wt(k(e, s));
  }, n;
}
const g = wt(z);
g.Axios = j;
g.CanceledError = M;
g.CancelToken = Ae;
g.isCancel = lt;
g.VERSION = bt;
g.toFormData = ne;
g.AxiosError = m;
g.Cancel = g.CanceledError;
g.all = function(t) {
  return Promise.all(t);
};
g.spread = or;
g.isAxiosError = ir;
g.mergeConfig = k;
g.AxiosHeaders = x;
g.formToJSON = (e) => ut(a.isHTMLForm(e) ? new FormData(e) : e);
g.getAdapter = yt.getAdapter;
g.HttpStatusCode = Se;
g.default = g;
const G = g.create({
  baseURL: "https://asblr.app/api"
});
G.interceptors.request.use(
  (e) => {
    const t = localStorage.getItem("_token");
    return t && (e.headers.Authorization = t), e;
  },
  (e) => Promise.reject(e)
);
const J = {
  getMyCreations: async ({ page: e = 1, limit: t = 20, type: n = "all", pageSize: r = 20 }) => {
    let s = {};
    switch (n) {
      case "published":
        s = { published: "True" };
        break;
      case "unpublished":
        s = { published: "False" };
        break;
      case "draft":
        s = { published: "Null" };
        break;
      case "notduplicatable":
        s = { is_template: "False" };
        break;
      case "duplicatable":
        s = { is_template: "True" };
        break;
    }
    const { data: i } = await G.get("/v2/creations/my-creations/", {
      params: {
        page: e,
        limit: t,
        row: r,
        ...s
      }
    });
    return i;
  },
  deleteCreation: async ({ Pblr: e }) => {
    const { data: t } = await G.post("/v2/creations/delete-creations/", {
      creations: e
    });
    return t;
  },
  renameCreation: async ({ Pblr: e, name: t }) => {
    const { data: n } = await G.post("/v2/creations/update-creations/", {
      creations: e,
      name: t
    });
    return n;
  }
}, W = typeof window < "u" && "indexedDB" in window, ar = {
  init: async () => {
    W && await N.initDB();
  },
  getMyCreations: async ({ page: e = 1, force: t = !1, type: n = "all", hostId: r, localOnly: s = !1 }) => {
    const i = `my-creations-${r}-${n}-${e}`;
    if (!W) {
      const l = await J.getMyCreations({ page: e, type: n });
      return {
        data: l.creations,
        total: l.jumlah,
        totalPage: l.page,
        page: e
      };
    }
    const o = await N.getApi({ key: i });
    if (o && !t) {
      const u = (await N.getMyCreations({ hostId: r })).filter((d) => n === "all" ? !0 : n === "published" ? d.Published : n === "unpublished" ? !d.Published && d.Published !== null : n === "draft" ? d.Published === null : n === "notduplicatable" ? !d.IsTemplate : n === "duplicatable" ? d.IsTemplate : !0).sort((d, b) => new Date(b.UpdatedTime || b.CreatedTime) - new Date(d.UpdatedTime || d.CreatedTime)).slice((e - 1) * 20, e * 20);
      if (u.map((d) => d.Pblr).join() === o.creationIds.join())
        return {
          data: u,
          total: o.total,
          totalPage: o.totalPage,
          page: e
        };
      const f = u.filter((d) => !o.creationIds.includes(d.Pblr));
      if (f.length > 0)
        for (const d of f)
          await N.deleteCreation({ Pblr: d.Pblr });
      if (s)
        return {
          data: [],
          total: 0,
          totalPage: 0,
          page: e
        };
    } else if (s)
      return {
        data: [],
        total: 0,
        totalPage: 0,
        page: e
      };
    const c = await J.getMyCreations({ page: e, type: n }).catch(async (l) => {
      console.log("Error in getMyCreations", l);
      const u = await ar.getMyCreations({ page: e, force: !1, type: n, hostId: r, localOnly: !0 });
      return {
        creations: u.data,
        jumlah: u.total,
        page: u.page
      };
    });
    return await N.updateOrCreateCreations({ creations: c.creations }), await N.setApi({
      key: i,
      value: {
        total: c.jumlah,
        totalPage: c.page,
        creationIds: c.creations.map((l) => l.Pblr)
      }
    }), {
      data: c.creations,
      total: c.jumlah,
      totalPage: c.page,
      page: e
    };
  },
  deleteCreation: async ({ Pblr: e }) => (await J.deleteCreation({ Pblr: e }).catch((t) => {
    console.log("Error in deleteCreation", t);
  }), W && await N.deleteCreation({ Pblr: e }).catch((t) => {
    console.log("Error in deleteCreation", t);
  }), !0),
  renameCreation: async ({ Pblr: e, name: t }) => (await J.renameCreation({ Pblr: e, name: t }).catch((n) => {
    console.log("Error in renameCreation", n);
  }), W && await N.renameCreation({ Pblr: e, name: t }).catch((n) => {
    console.log("Error in renameCreation", n);
  }), !0)
};
export {
  N as IDBServices,
  ar as Services
};
